<template>
  <span class="required-asterisk">*</span>
</template>

<script>
export default {};
</script>

<style>
.required-asterisk {
  font-weight: bold !important;
  color: red !important;
}
</style>
